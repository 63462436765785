import React, { useState } from "react";
import styles from "../css/scholarships.module.css";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";


function ScholarshipCard(props) {
  const { title, amount, descText1, desc, descText2 } = props.scholarship;
  console.log(props);
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  }
   return (
        <div className={styles.info}>
          <div className={styles.compHeader}>
            <p className={styles.title}>{title}</p>
            <div title="Toggle View" className={styles.toggleIcon} onClick={toggleDescription}>
             {isExpanded ? <IoIosArrowDropup/> : <IoIosArrowDropdown/>}
            </div>
          </div>

        {isExpanded && (
          <div className={styles.compDetails}>
            <p className={styles.amount}>{amount}</p>
            <p className={styles.descText1}>{descText1}</p>
            <p className={styles.desc}>{desc}</p>
            <p className={styles.descText2}>{descText2}</p>
          </div>
      )}       
        </div>
   );
 }
export default ScholarshipCard;

import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.css";
import { App } from "./components";
import { HelmetProvider } from "react-helmet-async"; //seo part: to provide each page its own meta tag using <helmet><helmet>
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
    <App />
    </HelmetProvider>
  </React.StrictMode>
);

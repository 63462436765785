import React from "react";
import BlogOverview from "../components/BlogOverview";
import Banner from "../components/Banner";
import {useEffect,useState} from "react";
import {getAllDocs} from "../config";

const OurBlogs = () => {
  const [blogs,setBlogs]=useState([]);
  const getBlogs=async()=>{
    let data=await getAllDocs("ourblogs");
    console.log("Fetched data:", data);
    if(data!=null){
      setBlogs(data);
    }
  };
  useEffect(()=>{
    getBlogs();
  },[]);
  return (
    <div>
      <Banner 
         heading = "Our Blogs"
        />
      <BlogOverview blogs={blogs} />
    </div>
  );
};

export default OurBlogs;

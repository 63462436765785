import React, { useState, useEffect } from "react";
import "../css/labs.css";
import developmentalNeurobiologyImg from "../assets/images/developmentalNeurobiology.jpg";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { Helmet } from "react-helmet-async";
import Banner from "../components/Banner";
import { getAllDocs } from "../config";

const Labs = () => {
  const [labdesc, setLabDesc] = useState({});
  const [lab, setLab] = useState([]);

  const handleView = (menu) => {
    setLabDesc((prevState) => ({
      ...prevState,
      [menu]: !prevState[menu],
    }));
  };

  const getLab = async () => {
    let data = await getAllDocs("lab");
    if (data != null) {
      setLab(data);
    }
  };

  useEffect(() => {
    getLab();
  }, []);

  useEffect(() => {
    if (lab.length > 0) {
      const initialLabDesc = lab.reduce((acc, labItem) => {
        acc[labItem.data.Lab] = false;
        return acc;
      }, {});
      setLabDesc(initialLabDesc);
    }
  }, [lab]);

  return (
    <>
      <Helmet>
        <title>BioSoc IITK | Labs</title>
        <meta
          name="description"
          content="Explore the cutting-edge research labs at IIT Kanpur. Learn about the Developmental Neurobiology Lab, Skeletal Biology Lab, Fly Lab, GPCR Biology Lab, Signalling Lab, and Decision Lab. Discover groundbreaking research in areas like nervous system development, skeletal biology, genetics, GPCR biology, cellular signaling, and decision-making processes."
        />
        <link rel="canonical" href="/labs" />
      </Helmet>
      <>
        <Banner heading="Laboratories of Innovation" />
        <div className="labs-container">
          <div className="labs-grid">
            {lab.map((lab, index) => (
              <div key={index} className="lab-item">
                <div className="lab-left">
                  <div className="head">
                    <div className="lab-image">
                      <img src={lab.data.Image|| developmentalNeurobiologyImg} />
                    </div>

                    <div className="lab-details">
                      <h2 className="lab-name" style={{ color: "#015fb1" }}>
                        {lab.data.Lab}
                      </h2>
                      <div>
                        <div className="pi-name">
                          <p style={{ color: "rgb(83, 83, 83)" }}>
                            PI: {lab.data.Prof}
                          </p>
                          <span onClick={() => handleView(`${lab.data.Lab}`)}>
                            {labdesc[lab.data.Lab] ? (
                              <IoIosArrowDropleftCircle
                                title="Close Description"
                                style={{ height: "25px", width: "25px" }}
                              />
                            ) : (
                              <IoIosArrowDroprightCircle
                                title="Show Description"
                                style={{ height: "25px", width: "25px" }}
                              />
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lab-right-desc">
                  {labdesc[lab.data.Lab] && (
                    <div className="lab-desc">
                      <p
                        className="lab-desc-detail"
                        style={{ color: "rgb(83, 83, 83)" }}
                      >
                        {lab.data.Description}
                      </p>
                      {/* <a
                    href={lab.link}
                    target="_blank"
                    style={{ color: "rgb(83, 83, 83)" }}
                  >
                    visit lab webpage
                  </a> */}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    </>
  );
};

export default Labs;

import React, { useState } from "react";
import styles from "../css/competitions.module.css";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
function CompetitionCard(props) {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={styles.indvcard}>
      <div className={styles.compHeader}>
        <p className={styles.compTitle}>{props.title}</p>

        <div title="Toggle View" className={styles.toggleIcon} onClick={toggleDescription}>
          {/* {isExpanded ? "-" : "+"} */}
          {isExpanded ? <IoIosArrowDropup/> : <IoIosArrowDropdown/>}
        </div>
      </div>

      {isExpanded && (
        <div className={styles.compDetails}>
          <p className={styles.compDesc}>{props.desc}</p>
          <a
            className={styles.compLink}
            href={props.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            Visit Website
          </a>
        </div>
      )}
    </div>
  );
}

export default CompetitionCard;



import React from "react";

function CompanySection(props) {
  return (
    <div className="achievement">
      <h2>{props.heading}</h2>
      <div>{props.overview}</div>
    </div>
  );
}

export default CompanySection;

import React from "react";
import "../css/BlogOverview.css";

const BlogOverview = ({ blogs }) => {
  return (
    <div className="blog-overview-container">
      {blogs.map((blog, index) => (
        <div className="blog-card" key={index}>
          <img src={blog.data.image} alt={blog.title} className="blog-image" />
          <div className="blog-content">
            <p className="blog-date">{blog.data.date}</p>
            <h3 className="blog-title">{blog.data.title}</h3>
            <p className="blog-description">{blog.data.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BlogOverview;

import { ProjectCard } from "../components/index";
import Styles from "../css/projectcard.module.css";
import { useState, useEffect } from "react";
import { getAllDocs } from "../config";
import Banner from "../components/Banner";

const Projects = () => {
  const [profProjects, setProfProjects] = useState([]);
  const [societyProjects, setSocietyProjects] = useState([]);
  const [otherProjects, setOtherProjects] = useState([]);


    const getprofProjects = async () => {
      let data = await getAllDocs("profProjects");
      if (data != null) {
        setProfProjects(data);
      }
    };
    
    const getSocietyProjects = async () => {
      let data = await getAllDocs("societyProjects");
      if (data != null) {
        setSocietyProjects(data);
      }
    };
    
    const getOtherProjects = async () => {
      let data = await getAllDocs("otherProjects");
      if (data != null) {
        setOtherProjects(data);
      }
    };
  
    useEffect(() => {
      getprofProjects();
      getSocietyProjects();
      getOtherProjects();
    }, []);
    
  return (
    <>
      <Banner heading="Transform Ideas Into Reality Through Projects"/>
      <div className={Styles.background}>
        <div className={Styles.cards}>
        {otherProjects.map((project) => (   //this is society projects from database
            <ProjectCard key={project.id} project={project} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Projects;

import styles from "../css/scholarships.module.css";
import ScholarshipCard from "../components/ScholarshipCard";
import { scholarship_data } from "../data/scholarshipData";
import Banner from "../components/Banner";
import React, { useState, useEffect } from "react";
import { getAllDocs } from "../config";

const Scholarships = () => {
  const[scholarship,setScholarships] = useState([]);    //scholarship --> array

  //fetch data from firestore
  const fetchScholarships = async() => {
    let data = await getAllDocs("scholarshipData");
    if(data!=null){
      setScholarships(data);
    }
  };

  useEffect(()=>{
    fetchScholarships();
  },[]);
  
  return (
    <div className={styles.scholarships}>
        <Banner heading = "Scholarships"/>
        <div className={styles.container}>
          {scholarship.map((item, index) => (  //item --> collection name/array
            <ScholarshipCard
                key={index} 
                scholarship={item.data}     //item.data --> 
            />
          ))}
        </div>
    </div>
  );
}

export default Scholarships;

import styles from "../css/app.module.css";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { NavbarDesktop } from "../components";
import {
  Home,
  AboutUs,
  Blog,
  OfferOpportunity,
  Team,
  Events,
  Error,
  Competitions,
  Scholarships,
  Projects,
  Login,
  StudentAchievements,
  Collaborations,
  Gallery,
  Labs,
  ProfAchievements,
  ProjectOfferings,
  OngoingProjects,
  CallForTalks,
  AlumniNetwork,
  FarewellDiary,
  OurStudents,
  EngageWithUs,
  Achievements,
  Opportunity,
} from "../pages";
import { Footer, Navbar } from "../components";
// import  from "../pages/Projects";
import PreLoader from "./Preloader";
import ContactUs from "../pages/ContactUs";
import Team2324 from "../pages/Team2324";
import Team2425 from "../pages/Team2425";
import Scholarships1 from "../pages/ScholarshipsBasicsite";
import Expertise from "../pages/Expertise.js";
import OurBlogs from "../pages/OurBlogs.js";
// import StudentAchievements from "../pages/StudentAchievements";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1200);
  }, []);

  return (
    <div className={styles.app}>
      {isLoading ? (
        <PreLoader />
      ) : (
        <BrowserRouter>
          <Navbar />
          <NavbarDesktop />
          <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route exact path="/aboutUs" element={<AboutUs />}></Route>
            <Route exact path="/events" element={<Events />}></Route>
            <Route exact path="/activities" element={<Home />}></Route>
            <Route exact path="/news" element={<Home />}></Route>
            <Route exact path="/contactus" element={<ContactUs />}></Route>
            <Route exact path="/team/team-23-24" element={<Team2324 />}></Route>
            <Route exact path="/team/team-24-25" element={<Team2425 />}></Route>
            <Route exact path="/team" element={<Team />}></Route>
            <Route exact path="/projects" element={<Projects />}></Route>
            <Route exact path="/login" element={<Login />}></Route>
            <Route exact path="/our-students" element={<OurStudents/>}></Route>
            <Route exact path="/engage-with-us" element={<EngageWithUs/>}></Route>
            <Route
              exact
              path="/competitions"
              element={<Competitions />}
            ></Route>
            <Route exact path="/contactus" element={<Home />}></Route>
            <Route exact path="/events" element={<Events />}></Route>
            <Route exact path="/competitions" element={<Home />}></Route>
            <Route
              exact
              path="/scholarships"
              element={<Scholarships />}
            ></Route>
            <Route
              exact
              path="/scholarships1"
              element={<Scholarships1 />}
            ></Route>
            <Route
              exact
              path="/student-achievements"
              element={<StudentAchievements />}
            ></Route>
            <Route excat path="/achievements" element={<Achievements/>}></Route>
            {/* <Route
              exact
              path="/Collaborations"
              element={<Collaborations />}
            ></Route> */}
            <Route exact path="/gallery" element={<Gallery />}></Route>
            <Route exact path="/labs" element={<Labs />}></Route>
            <Route
              exact
              path="/prof-achievements"
              element={<ProfAchievements />}
            ></Route>
            <Route
              exact
              path="/ProjectOfferings"
              element={<ProjectOfferings />}
            ></Route>
            {/* <Route
              exact
              path="/OngoingProjects"
              element={<OngoingProjects />}
            ></Route> */}
            <Route
              exact
              path="/call-for-talks"
              element={<CallForTalks />}
            ></Route>
            <Route
              exact
              path="/AlumniNetwork"
              element={<AlumniNetwork />}
            ></Route>
            <Route
              exact
              path="/FarewellDiary"
              element={<FarewellDiary />}
            ></Route>

            <Route path="*" element={<Error />}></Route>
            <Route path="/blog" element={<Blog />}></Route>
            <Route path="/opportunity" element={<Opportunity />}></Route>
            <Route path="/expertise" element={<Expertise />}></Route>
            <Route path="/ourblogs" element={<OurBlogs />}></Route>
          </Routes>
          <Footer />
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;

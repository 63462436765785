import React from "react";
import { useEffect, useState } from "react";
import styles from "../css/ourstudents.module.css";
import { FaHandshakeSimple } from "react-icons/fa6";
import { GrWorkshop } from "react-icons/gr";
import { FaHandHoldingUsd } from "react-icons/fa";
import { FaHandHoldingHeart } from "react-icons/fa";
import { Helmet } from "react-helmet-async";
import { getAllDocs } from "../config";
import CompanySection from "../components/CompanySection";
const EngageWithUs = () => {
  const [engagewithus, setEngageWithUs] = useState([]);
    const getEngageWithUs = async () => {
      let data = await getAllDocs("forcompanies-engagewithus");
      if (data != null) {
        setEngageWithUs(data);
      }
    };
    useEffect(() => {
      getEngageWithUs();
    }, []);
  return (
    <>
      {/* seo */}
      <Helmet>
        <title>BioSoc | Engage With Us</title>
        <meta name="description" content="Be the Part of Our community" />
        <link rel="canonical" href="/engage-with-us" />
      </Helmet>
      <div className={styles.ourStudents}>
        <div className={styles.banner}>
          <h1 className={styles.heading}>Be The Part of Our Community</h1>
        </div>
        <div className={styles.subHeadings}>
          <div className={styles.subHeading}>
            <a href="#1">
              <FaHandshakeSimple className={styles.subheadicon} />
            </a>
            <p>Project Collaboration</p>
          </div>
          <div className={styles.subHeading}>
            <a href="#2">
              <GrWorkshop className={styles.subheadicon} />
            </a>
            <p>Workshop</p>
          </div>
          <div className={styles.subHeading}>
            <a href="#3">
              <FaHandHoldingHeart className={styles.subheadicon} />
            </a>
            <p>CSR</p>
          </div>
          <div className={styles.subHeading}>
            <a href="#4">
              <FaHandHoldingUsd className={styles.subheadicon} />
            </a>
            <p>Sponsorship</p>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.description}>
            IIT Kanpur students are known for their incredible intelligence,
            creativity, and determination. They succeed in a challenging
            academic setting that encourages them to think critically, solve
            complex problems, and engage in innovative research. Beyond their
            studies, they participate in various activities, excelling in areas
            like entrepreneurship, arts, and sports. Working together and
            striving for excellence, IITK students make meaningful contributions
            to society and the world, reflecting their values of hard work,
            honesty, and social responsibility.
          </div>
          <div className="engagewithus-container">
            {engagewithus.map((engagewithus, index) => (
              <CompanySection
                key={index}
                heading={engagewithus.data.heading}
                overview={engagewithus.data.overview}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default EngageWithUs;

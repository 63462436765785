import { CompetitionCard } from "../components/index";
import Styles from "../css/competitions.module.css";
import { FaGlobe } from "react-icons/fa";
import { GiIndiaGate } from "react-icons/gi";
import {InterNationalCompData,NationalCompData,} from "../data/competitionData";

const Competitions = () => {
  return (
    <div className={Styles.background}>
      <div className={Styles.banner}>
        <div className={Styles.subHeadings}>
          <div className={Styles.subHeading}>
            <a href="#1">
            <FaGlobe className={Styles.subheadicon} />
            <p>International</p>
            </a>
          </div>
          <div className={Styles.subHeading}>
            <a href="#2">
            <GiIndiaGate className={Styles.subheadicon} />
            <p>National</p>
            </a>
          </div>
        </div>
        <h2 className={Styles.titles}>Competitions</h2>
      </div>
      <div className={Styles.AllContentContainer}>
      <div>
      <h2 id="1" className={Styles.subTitles}> International Competitions</h2>
      {InterNationalCompData.map((data, index) => (
        <CompetitionCard
          key={index}
          title={data.title}
          desc={data.desc}
          link={data.link}
        />
      ))}
      </div>
      <div>
      <h2 id="2" className={Styles.subTitles}>National Competitions</h2>
      {NationalCompData.map((data, index) => (
        <CompetitionCard
          key={index}
          title={data.title}
          desc={data.desc}
          link={data.link}
        />
      ))}
      </div>
      </div>
    </div>
  );
};

export default Competitions;
